<template>
  <div class="p-grid card page">
    <div class="p-col-12">
      <div class="p-col-12">
        <Breadcrumb :model="typeState==2?items1:items"/>
      </div>

      <div class="p-p-2" style="width: 100%">
        <DataTable ref="dt" :value="data" v-model:selection="selectedProducts" dataKey="id"
                   :auto-layout="true"
                   :scrollable="true" responsiveLayout="scroll"
                   :paginator="true" :rows="10"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   :rowsPerPageOptions="[5,10,25]"
                   currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计{totalRecords}记录">
          <template #header>
            <div class="p-d-flex p-col">
              <Button @click="update" :label="typeState==2?'约稿':'问题发布'" class="p-mr-2" icon="pi pi-fw pi-plus"/>
              <InputText v-model="title" placeholder="标题"/>

              <Dropdown class="p-ml-3" v-model="state" :options="problemsState" optionLabel="name"
                        option-value="code"
                        placeholder="分配状态" :show-clear="true"/>
              <Dropdown class="p-ml-3" v-model="industry" :options="selectedIndustry" optionLabel="name"
                        placeholder="所属行业" option-value="name" :show-clear="true"/>
              <Button @click="getData" class="p-ml-2">查询</Button>
              <Button @click="showInvitePeople" :class="{'p-disabled':selectedProducts.length==0}"
                      :label="typeState==2?'约稿':'分配问题'" class="p-ml-2"/>
            </div>
          </template>
          <template #empty>
            <p style="text-align: center;width: 100%">无数据</p>
          </template>
          <Column style="min-width: 4rem" selectionMode="multiple"></Column>

          <Column style="min-width: 20rem" field="title" header="标题" :sortable="false">

          </Column>
          <Column style="min-width: 10rem" field="gainful" header="报酬">
          </Column>
          <Column style="min-width: 10rem" field="state" header="状态">
            <template #body="a">
              {{ a.data.state == 0 ? '未分配' : '已分配' }}
            </template>
          </Column>
          <Column style="min-width: 20rem" field="industry" header="行业" :sortable="false">
          </Column>
          <Column style="min-width: 15rem" field="addTime" header="发布时间" :sortable="true">
            <template #body="slotProps">
              {{
                new Date(+new Date(slotProps.data.addTime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
              }}
            </template>
          </Column>
          <Column style="min-width: 20rem" field="brief" header="简介">
          </Column>
          <Column frozen alignFrozen="right" style="min-width: 25rem" header="操作" headerStyle="width:250px"
                  :exportable="false">
            <template #body="slotProps">
              <Button @click="$router.push('/expertArticle?id='+slotProps.data.id)" class="p-button-sm p-mr-2">解答文章
              </Button>
              <Button class="p-button-sm p-mr-2" @click="update(slotProps.data)">编辑</Button>
              <Button class="p-button-sm p-mr-2" :class="{'p-disabled':slotProps.data.state==0}"
                      @click="gotoView(slotProps.data)">解答人员列表
              </Button>
              <Button v-if="slotProps.data" class="p-button-danger p-button-sm "
                      @click="deleteQuestion(slotProps.data)">删除
              </Button>
            </template>
          </Column>
        </DataTable>
        <Dialog v-model:visible="deleteProductDialog" :style="{width: '350px'}" header="确认" :modal="true">
          <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
            <span v-if="product">你确认要删除 <b>{{ product.title }}</b>?</span>
          </div>
          <template #footer>
            <Button label="否" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
            <Button label="是" icon="pi pi-check" class="p-button-text" @click="deleteProduct(product.id)"/>
          </template>
        </Dialog>
      </div>
    </div>
    <DemandSeminar @close="closeDialog" ref="addOrUpdate" v-if="addOrUpdate"></DemandSeminar>
    <Toast/>
    <InvitePeople v-on:close="show=false" v-if="show" ref="InvitePeople"></InvitePeople>
  </div>
</template>

<script>
import DemandSeminar from "../memberManagement/DemandSeminar";

import InvitePeople from "@/views/modules/public/InvitePeople";

export default {
  name: "",
  components: {DemandSeminar, InvitePeople},
  data() {
    return {
      typeState: 1,
      product: {},
      deleteProductDialog: false,
      addOrUpdate: false,
      selectedProducts: [],
      fileUploadUrl: this.$global_msg.frontendUrl + "/activityRelease/upload",
      show: false,
      title: null,
      state: null,
      industry: null,
      problemStatus: null,
      selectedIndustry: [],
      items: [
        {label: '问题管理', to: '/problemAllocation'},
      ],
      items1: [
        {label: '约稿', to: '/problemAllocation?state=2'},
      ],
      problemsState: [
        {name: '待分配', code: '0'},
        {name: '已分配', code: '1'},
      ],
      data: []
    }
  },
  watch: {
    '$route'() {
      this.typeState = this.$route.query.state == 2 ? 2 : 1
      this.getData()
    }
  },
  mounted() {
    this.typeState = this.$route.query.state == 2 ? 2 : 1
    this.getData()
    this.getIndustry()
  },
  methods: {
    showInvitePeople() {
      this.show = true
      this.$nextTick(() => {
        this.$refs.InvitePeople.init(2, this.selectedProducts)
      })
    },
    closeDialog() {
      this.addOrUpdate = false
      this.getData()
    },
    getIndustry() {
      this.$http.get('/dictionary/selectValue', {params: {key: 'suoshuhangye'}}).then((res) => {
        let list = res.data.myValue.split(",");
        let resList = []
        for (let i in list) {
          let item = {}
          item.name = list[i]
          resList.push(item)
        }

        this.selectedIndustry = resList;
      })
    },
    deleteProduct(id) {
      this.$http.get('/question/deleteQuestion', {params: {'id': id}}).then(() => {
        this.deleteProductDialog = false;
        this.$toast.add({severity: 'success', summary: '成功', detail: '删除成功', life: 3000});
        this.getData()
      })
    },
    deleteQuestion(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    update(data) {
      this.addOrUpdate = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(this.typeState, data)
      })
    },
    getData() {
      this.$http.get('/question/getQuestionByType', {
        params: {
          type: this.typeState,
          title: this.title,
          state: this.state,
          industry: this.industry
        }
      }).then((res) => {
        this.data = res.data
        console.log(res.data)
      })
    },

    downloadTemplate() {
      window.open('/static/file/people_template.xlsx')
    },
    goto(id) {
      this.$router.push('/questionDetails?id=' + id)
    },

    gotoView(data) {
      this.$router.push('/participants?id=' + data.id + '&tableType=2')
    },
  }
}
</script>

<style scoped>

</style>
